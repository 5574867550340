import React, { useState, useEffect, useRef } from 'react';
import { Nav, Navbar, Row, Col, Container, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../style/hobbies.css';
import { hobbies } from '../assets/data/userdata';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProfile } from '../assets/data/providerContext';
import { allowWriteAccess } from '../functions/util_fn';
import { addHobbyToProfile } from '../functions/firebase';

function HobbiesList(props) {
  const [showModal, setShowModal] = useState(false);
  const [newHobbyName, setNewHobbyName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const profileContext = useProfile();
  const profileInfo = profileContext == null ? null : profileContext.profileInfo;

  const navigate = useNavigate();
  var location = useLocation()

  // Focus input when modal opens
  useEffect(() => {
    if (showModal && inputRef.current) {
      // Small delay to ensure the modal is rendered
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [showModal]);

  // Given a hobby id, navigate to the hobby page
  const goToHobbyPage = (hid) => {
    navigate(`/u/${props.user.username}/hobby/${hid}`, {
      state: {
        "origin_url": location.pathname
      }
    })
  }

  const handleAddHobby = async () => {
    if (!newHobbyName.trim()) {
      setError('Please enter a hobby name');
      return;
    }

    setIsSubmitting(true);
    setError('');
    
    try {
      const result = await addHobbyToProfile(props.user.uid, newHobbyName);
      setNewHobbyName('');
      setShowModal(false);
      
      // Refresh the profile context to show the new hobby
      if (profileContext?.refreshProfileInfo) {
        await profileContext.refreshProfileInfo();
      }
      
      // Navigate to the edit page for the newly created hobby
      if (result && result.hobbyId) {
        navigate(`/u/${props.user.username}/hobby/${result.hobbyId}?edit=true`);
      }
    } catch (err) {
      console.error('Error adding hobby:', err);
      setError('Failed to add hobby. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (profileInfo?.hobbies) {
    console.log(profileInfo.hobbies);
  }

  return (
    <div className="hobbies-list">
      { profileInfo == null ? <div></div> :
      <Container fluid className="p-0">
        {profileInfo.hobbies.map((hobby, index) => (
          <Row className="g-0 m-0">
            <Col xs="12" className="p-0">
              <div key={index} className="hobby-card" onClick={() => goToHobbyPage(hobby.id)}>
                <header className=''
                  style={{
                    width: '100%',
                    backgroundImage: `url(${hobby.cover_photo_url || ''})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                <div className='black-gradient'>
                  <Container>
                    <Row>
                      <Col xs="11">
                      <div className={`post-count-label`}>
                          {hobby.post_count === 0 ? 
                            <span>No Posts</span> :
                            <span>{hobby.post_count} Posts</span>
                          }
                        </div>
                        <h2>{hobby.hobbyName}</h2>
                        <p style={{marginBottom: '16px'}}>{hobby.tagline || ''}</p>
                      </Col>
                      <Col xs="1" className='right-align subtext-gray'>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </header>
            </div>
            </Col>
          </Row>
        ))}
        
        {allowWriteAccess(props.user) && (
          <Row className="g-0 m-0 mt-3">
            <Col xs="12" className="p-0">
              <Button 
                className="secondary-wide-btn" 
                onClick={() => setShowModal(true)}
                style={{ margin: '0 16px', width: 'calc(100% - 32px)', padding: '12px 0' }}
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" /> Add New Hobby
              </Button>
            </Col>
          </Row>
        )}
      </Container>}

      {/* Custom Modal */}
      {showModal && (
        <div className="custom-modal-overlay" onClick={() => setShowModal(false)}>
          <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
            
            <div className="custom-modal-body">
              <div className="form-group">
                <label>Hobby Name</label>
                <input
                  ref={inputRef}
                  type="text"
                  className="form-control"
                  placeholder="Enter hobby name"
                  value={newHobbyName}
                  onChange={(e) => setNewHobbyName(e.target.value)}
                />
              </div>
              {error && <p className="text-danger mt-2">{error}</p>}
            </div>
            <div className="custom-modal-footer">
              <button 
                className="btn primary-btn-fill"
                onClick={handleAddHobby}
                disabled={isSubmitting}
                style={{color: 'white'}}
              >
                {isSubmitting ? 'Adding...' : 'Add Hobby'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HobbiesList;

// <div className="hobbies-list">
// { profileInfo == null ? <div></div> :
// <Container fluid>
//   {profileInfo.hobbies.map((hobby, index) => (
//   <Row key={index} className="hobby-row" onClick={(() => { goToHobbyPage(hobby.id) })}>
//       <Col xs="2" className="icon-col">
//           <div className={`hobby-category-circle color-${hobby.color_index || '1'}`}></div>
//       </Col>
//       <Col xs="9" className="text-col">
//         <div className="hobby-name">{hobby.hobbyName}</div>
//         { hobby.post_count === 0 ? <div className="subtext-gray">No Posts</div> :
//           <div className="post-count">{hobby.post_count} Posts</div>
//         }
//       </Col>
//       <Col xs="1" className='right-align subtext-gray'>
//         <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
//       </Col>
//   </Row>
//   ))}
//   {/* { allowWriteAccess(props.user) ? 
//   <Row>
//     <Col xs="12">
//       <div className='secondary-wide-btn'><FontAwesomeIcon icon="fa-solid fa-plus" /> Add Hobby</div>
//     </Col>
//   </Row> : <></>} */}
  
// </Container>}

// </div>

