import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../style/aboutPage.css';
import AllPostsFeed from '../components/allPostsFeed';
import { useProfile } from '../assets/data/providerContext';
import { getProjects } from '../functions/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useQuery } from '@tanstack/react-query';
import { allowWriteAccess } from '../functions/util_fn';

library.add(fab);

function AboutPage({ user }) {
  const profileContext = useProfile();
  const profileInfo = profileContext == null ? null : profileContext.profileInfo;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  
  // CSS styles extracted as variables
  const bannerStyles = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#f8f9fa',
    borderTop: '1px solid #dee2e6',
    padding: '16px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
    boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.15)'
  };
  
  const bannerContentStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };
  
  const bannerTextStyles = {
    margin: 0,
    fontSize: '16px'
  };
  
  const ctaButtonStyles = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '6px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    minWidth: '100px'
  };
  
  const closeButtonStyles = {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: '#6c757d',
    padding: '0px',
    paddingLeft: '10px'
  };
  
  console.log("User state:", user);
  console.log("Banner visibility:", showBanner);
  console.log("Banner condition:", !(user && Object.keys(user).length > 0) && showBanner);

  const { data: projects = [] } = useQuery({
    queryKey: ['projects', user?.uid],
    queryFn: () => getProjects(user.uid, null),
    enabled: !!user?.uid,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  const canEdit = allowWriteAccess(user || {});

  const goToHobbyPage = (hobbyId) => {
    navigate(`/u/${user.username}/hobby/${hobbyId}`, {
      state: {
        "origin_url": location.pathname
      }
    });
  };

  const goToProjectPage = (project) => {
    navigate(`/u/${user.username}/project/${project.id}`, {
      state: {
        "origin_url": location.pathname,
        "project": project
      }
    });
  };

  useEffect(() => {
    // Check if user is authenticated by looking at the auth object
    import('../functions/firebase').then(({ auth }) => {
      const unsubscribe = auth.onAuthStateChanged((authUser) => {
        setIsLoggedIn(!!authUser);
        setShowBanner(!authUser);
      });
      
      return () => unsubscribe();
    });
  }, []);

  return (
    <div className="content-below-nav remove-top-margin">
      <Container fluid>
        <div className="about-page">
          <Row>
            <Col xs={12}>
              <section className="bio-section">
                {profileInfo?.bio ? (
                  <p className="bio-text">{profileInfo.bio}</p>
                ) : (
                  canEdit && (
                    <div 
                      className="empty-bio-prompt"
                      onClick={() => navigate(`/u/${user.username}/edit`)}
                      style={{
                        border: '2px dashed #ccc',
                        padding: '20px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        color: '#666'
                      }}
                    >
                      Write your bio and add social links here
                    </div>
                  )
                )}
                
                {profileInfo?.socialLinks && (
                  <div className="social-links">
                    {profileInfo.socialLinks?.etsy && (
                      <a href={profileInfo.socialLinks.etsy} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-brands fa-etsy" />
                        <span>Etsy</span>
                      </a>
                    )}
                    {profileInfo.socialLinks?.instagram && (
                      <a href={profileInfo.socialLinks.instagram} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-brands fa-instagram" />
                        <span>Instagram</span>
                      </a>
                    )}
                    {profileInfo.socialLinks?.tiktok && (
                      <a href={profileInfo.socialLinks.tiktok} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-brands fa-tiktok" />
                        <span>TikTok</span>
                      </a>
                    )}
                    {profileInfo.socialLinks?.facebook && (
                      <a href={profileInfo.socialLinks.facebook} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-brands fa-facebook" />
                        <span>Facebook</span>
                      </a>
                    )}
                    {profileInfo.socialLinks?.twitter && (
                      <a href={profileInfo.socialLinks.twitter} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
                        <span>X</span>
                      </a>
                    )}
                    {profileInfo.socialLinks?.website && (
                      <a href={profileInfo.socialLinks.website} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon="fa-solid fa-globe" />
                        <span>Website</span>
                      </a>
                    )}
                  </div>
                )}
              </section>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12}>
              <section className="hobbies-section">
                <Row>
                  <Col xs={12}>
                    <div className="section-header">
                      <h4>Hobbies</h4>
                    </div>
                  </Col>
                </Row>
                <div className="cards-container">
                  {profileInfo?.hobbies?.map((hobby, index) => (
                    <div 
                      className="carousel-card" 
                      key={index}
                      onClick={() => goToHobbyPage(hobby.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div 
                        className="tile-image" 
                        style={{
                          backgroundImage: `url(${hobby.cover_photo_url || ''})`
                        }}
                      ></div>
                      <div className="tile-title">{hobby.hobbyName}</div>
                    </div>
                  ))}
                </div>
              </section>
            </Col>
          </Row>

          {projects.length > 0 && (
            <Row className="mt-4">
              <Col xs={12}>
                <section className="projects-section">
                  <Row>
                    <Col xs={12}>
                      <div className="section-header">
                        <h4>Projects</h4>
                        <div 
                          className="view-all" 
                          onClick={() => navigate(`/u/${user.username}/profile/projects`)}
                          style={{ cursor: 'pointer' }}
                        >
                          View All
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="cards-container">
                    {projects.slice(0, 4).map((project, index) => (
                      <div 
                        className="carousel-card" 
                        key={index}
                        onClick={() => goToProjectPage(project)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div 
                          className="tile-image" 
                          style={{
                            backgroundImage: `url(${project.gallery?.[0] || project.imageUrl || ''})`
                          }}
                        ></div>
                        <div className="tile-title">{project.title}</div>
                      </div>
                    ))}
                    {projects.length > 4 && (
                      <div 
                        className="carousel-card see-more" 
                        onClick={() => navigate(`/u/${user.username}/projects`)}
                      >
                        <div className="tile-image see-more-tile">
                          <div className="see-more-text">See More</div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col xs={12}>
              <section className="posts-section">
                <h4>Latest Posts</h4>
              </section>
            </Col>
          </Row>
        </div>
      </Container>
      
      <AllPostsFeed user={user} />
      
      {/* Only show banner for non-logged in users */}
      {!isLoggedIn && showBanner && (
        <div className="signup-banner" style={bannerStyles}>
          <div className="banner-content" style={bannerContentStyles}>
            <p style={bannerTextStyles}>Create your profile & share the story of your hobbies</p>
            <button 
              style={ctaButtonStyles}
              onClick={() => window.location.href = 'https://hobbi.ist/'}
            >
              Sign Up
            </button>
          </div>
          <button 
            style={closeButtonStyles}
            onClick={() => setShowBanner(false)}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default AboutPage; 